import React from 'react'
import Layout from "@layout/layout"
import SEO from "@layout/seo";

export default () => {
  return (
    <Layout>
      <SEO
        title={'Kontakt - Tiliamed'}
        description={'Kontakt Andrzej Lipa: +48 601 230 555 Kontakt Michał Lipa: +48 696 999 920 Kontakt Małgorzata Olszewska (Szkoła Rodzenia): +48 604 300 271'}
      />
      <div className="content">
        <main>
          <section className="contact page-margin">
            <div className="contact__map">
              <div className="acf-map">
                <div className="marker" data-lat="52.345416" data-lng="21.249171899999965"/>
              </div>
              <div className="contact__map__button visible-xs">
                <a href="//google.com/maps/?q=Kościelna 38, Wołomin, Polska" className="btn btn-primary">
                  włącz mapę
                </a>
              </div>
            </div>
            <div className="container">
              <div className="contact__content">
                <h1 className="contact__content__heading">
                  Kontakt
                </h1>
                <div className="contact__content__text">
                  <p><strong>Kontakt Andrzej Lipa:</strong><br/>
                    +48 601 230 555</p>
                  <p><strong>Kontakt Michał Lipa:</strong><br/>
                    +48 696 999 920</p>
                  <p><strong>Kontakt Małgorzata Olszewska (Szkoła Rodzenia):<br/>
                  </strong>+48 604 300 271</p>
                  <p/>
                  <div id="WEBzona" style={{display: 'none'}}>
                    <h2><strong>Przychodnia ginekologiczna Tiliamed w Wołominie.</strong></h2>
                    <p>Umów wizytę u lekarza ginekologa:</p>
                    <h3><strong>Andrzej Lipa – ginekolog w Wołominie (Warszawa)</strong></h3>
                    <h3><strong>Michał Lipa – ginekolog w Wołominie (Warszawa)</strong></h3>
                    <p>Lekarze prowadzą klinikę Tiliamed w Wołominie. Zakres działalności: położnictwo i
                      ginekologia.</p>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </main>
      </div>
      <script src="https://maps.googleapis.com/maps/api/js?key=AIzaSyAAtIxcrqYsO8Jerg6CTNVoXTcoSOO_R6g&ver=5.5.1"></script>
    </Layout>
  )
}
